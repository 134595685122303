import React from 'react';
import {
  Chip,
  Icon, Link, Pagination, Truncate,
} from '@fieldnation/platform-components';
import classes from './FeedbackList.scss';
import {
  Props, Result, WorkOrder,
} from './types.d';
import dateStringToLocalTime from './util';

const FeedbackList = ({
  feedback, onNext, onPrev, isDrawer,
}: Props): JSX.Element => {
  const getAttributes = (data: Result, type: string) => data?.attributes?.filter((d) => d.type === type);

  const emptyState = () => (
    <div className={classes.EmptyContent}>
      No feedback left
    </div>
  );

  const getTitle = (item: Result) => (
    <div className={classes.Dispatcher}>
      {item?.byUser?.firstName ? `${item?.byUser?.firstName} ${item?.byUser?.lastName}` : 'Work Order Manager'}
      <span className={classes.DateTime}>{` posted on ${dateStringToLocalTime(item?.dateAdded?.utc)}`}</span>
    </div>
  );

  const getAttributeList = (item: Result) => {
    const positive = getAttributes(item, 'positive') ?? [];
    const negative = getAttributes(item, 'negative') ?? [];
    const data = [...positive, ...negative];
    return data?.map((att) => (
      <div className={classes.ChipWrapper} key={att.name}>
        <Chip
          type={att?.type === 'positive' ? 'successDark' : 'warningDark'}
          hideCloseButton
          value={[att?.name]}
          size="xs"
          truncateText={false}
          cursor={false}
        />
      </div>
    )
    );
  };

  const getTypeOfWork = (workOrder: WorkOrder) => workOrder?.typeOfWork?.map((type) => {
    if (!type) return null;
    return (
      <div key={type} className={classes.TypeOfWork}>
        {type}
      </div>
    );
  });

  const items = () => feedback?.results?.map((item) => (
    <div key={item?.id} className={classes.TotalContent}>
      <div className={isDrawer ? classes.InfoAreaSM : classes.InfoArea}>
        <div className={isDrawer ? classes.WorkOrderIndoSM : classes.WorkOrderIndo}>
          <div className={classes.Info}>
            <div className={classes.Icon}>
              <Icon name="rateReviewSolid" size={18} block />
            </div>
            <div className={classes.InfoContent}>
              {Number.isInteger(item?.scores[0]?.score) && <div className={classes.Score}>{`${item?.scores[0]?.score} / ${item?.maxScore ?? 10}`}</div>}
              <div className={classes.WOLink}>
                <Link href={`/workorders/${item?.workOrderId}`}>{`WO ${item?.workOrderId}`}</Link>
              </div>
            </div>
            {!!(isDrawer && item?.workOrder?.title) && (
            <div className={classes.WorkOrderTitleSM}>
              <Truncate maxLines={1} wordBreak="break-word" text={item?.workOrder?.title} />
            </div>
            )}
          </div>
          {!!(item?.workOrder?.title && !isDrawer) && (
            <div className={classes.WorkOrderTitle}>
              <Truncate maxLines={1} wordBreak="break-word" text={item?.workOrder?.title} />
            </div>
          )}
          {!!item?.workOrder?.client?.name && (
            <div className={classes.ClientInfo}>
              <div className={classes.InfoTitle}>Client:</div>
              <div className={classes.Client}>
                <Truncate maxLines={1} wordBreak="break-word" text={item?.workOrder?.client?.name || ''} />
              </div>
            </div>
          )}
          {!!item?.workOrder?.typeOfWork?.length && (
            <div className={classes.WorkTypeArea}>
              <div className={classes.TypeTitle}>Work type:</div>
              {getTypeOfWork(item?.workOrder)}
            </div>
          )}
        </div>
        <div className={isDrawer ? classes.FeedbackInfoSM : classes.FeedbackInfo}>
          {!!item?.comment && <div className={classes.Comment} dangerouslySetInnerHTML={{ __html: item.comment }} />}
          <div className={classes.AttributeContent}>
            {getAttributeList(item)}
          </div>
        </div>
      </div>
      <div className={classes.ManagerInfo}><Truncate maxLines={1} wordBreak="break-word" text={getTitle(item)} /></div>
    </div>
  ));

  return (
    <section>
      {feedback?.results?.length > 0 ? items() : emptyState()}
      {(!!feedback?.results?.length && !isDrawer) && (
        <div className={classes.Pagination}>
          <Pagination
            disableNext={feedback?.metadata?.currentPage === feedback?.metadata?.totalPages}
            disablePrev={feedback?.metadata?.currentPage === 1}
            currentPage={feedback?.metadata?.currentPage}
            totalPages={feedback?.metadata?.totalPages}
            onNext={onNext}
            onPrev={onPrev}
          />
        </div>
      )}
    </section>
  );
};

export default FeedbackList;
