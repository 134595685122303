/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import NodeCache from 'node-cache';
import requestWrapper from '../requestWrapper';
import {
  MonolithProfile,
  MonolithTypeOfWork,
  JobHistoryFilters,
  MonolithContract,
  RatingAPIRes,
  LabelValue,
  ProviderCommitmentResponse,
  BackoutsDetailsFilters,
  BackoutsDetails,
  JobHistory,
  MonolithIndustry,
  MonolithLanguage,
  UserQualifications,
  BackgroundCheckApiResponse,
  DistanceResponse,
  MonolithPreferredGroups,
  ProviderSkillSegmentaionResponse,
} from './types.d';
import { getReputationServiceURL } from './utils';

const myCache = new NodeCache();

declare global {
  interface Window {
    contract: MonolithContract;
    _TYPES_OF_WORK: MonolithIndustry[];
    _LANGUAGES: MonolithLanguage;
    _GOOGLE_MAP_KEY?: string;
    work_order: {
      location: {
        coordinates: {
          latitude: number;
          longitude: number;
        },
      },
      id: number;
    };
  }
}

interface RequestOptions {
  headers?: { [index: string]: string };
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
  body?: any;
  onError?: (resp?: Response | Error) => any;
  throwExceptions?: boolean;
  timeout?: number;
}

export interface JobHistoryParams {
  userId: number;
  contextUserId?: number;
  contextGroupId?: number;
  companyId?: number;
  managedOrSelf?: boolean;
  filters?: JobHistoryFilters;
}

export async function getUserById(
  userId: number,
  options?: RequestOptions,
): Promise<MonolithProfile> {
  return <MonolithProfile>requestWrapper<MonolithProfile>(`/v2/users/${userId}`, {
    ...options,
    method: 'GET',
  });
}

export async function getUserData(
  vanityOrUserId: string | number,
  options: RequestOptions = {},
): Promise<MonolithProfile> {
  const cacheKey = `getUserData_${vanityOrUserId}_${JSON.stringify(options)}`;
  const cacheUser = myCache.get(cacheKey);
  if (cacheUser) {
    return cacheUser as MonolithProfile;
  }
  const user = await requestWrapper(`/v2/users/${vanityOrUserId}`, options);
  myCache.set(cacheKey, user, 120); // only 2 min cache for repeat calls
  return user as MonolithProfile;
}

export async function getTypeOfWorkByWorkOrder(
  workOrderId: number,
  options?: RequestOptions,
): Promise<MonolithTypeOfWork | null> {
  const wo: any = await requestWrapper<any>(
    `/v2/workorders/${workOrderId}?columns=type_of_work`,
    options,
  );

  return wo && wo?.type_of_work?.id ? wo.type_of_work : null;
}

export async function getUserRatings(
  params: JobHistoryParams,
): Promise<RatingAPIRes> {
  const {
    userId,
    companyId = 0,
    filters = {},
  } = params;

  const resp = await requestWrapper<RatingAPIRes>(`/p/user/${userId}/company/${companyId}/ratings`, {
    method: 'POST',
    body: {
      filters: {
        ...filters,
        userId,
      },
    },
  });

  return resp as RatingAPIRes;
}

export async function getContract(
  _options: RequestOptions = {},
): Promise<MonolithContract> {
  if (window.contract && typeof window.contract === 'object') {
    return window.contract;
  }

  return {
    curated_recruitment: {
      enabled: false,
    },
  };
}

export async function getCompanyList(): Promise<LabelValue[]> {
  const companies: any = await requestWrapper<any>(
    '/staff/companies',
  );

  return companies.map(
    (
      { company_id, name }:{ company_id: number, name: string },
    ) => ({ value: company_id.toString(), label: name }),
  );
}

export async function getProviderCommitment(
  params: JobHistoryParams,
  options: RequestOptions = {},
): Promise<ProviderCommitmentResponse|null> {
  const {
    userId,
    companyId,
    filters = {},
  } = params;

  const resp = await requestWrapper(`/p/user/${userId}/provider-commitments`, {
    ...options,
    method: 'POST',
    body: {
      filters: {
        ...filters,
        userId,
        contextCompanyId: companyId,
      },
    },
  });

  if (!resp) {
    return null;
  }

  return resp as ProviderCommitmentResponse;
}

export async function getUserContext(
  options: RequestOptions = {},
): Promise<MonolithProfile> {
  if (window.context && typeof window.context === 'object') {
    return window.context;
  }

  const cacheKey = `getUserContext_me_${JSON.stringify(options)}`;
  const cacheUser = myCache.get(cacheKey);
  if (cacheUser) {
    return cacheUser as MonolithProfile;
  }
  const ctx = await requestWrapper(
    '/v2/users/me?columns=id,experiments,features,company',
    options,
  );
  myCache.set(cacheKey, ctx, 60); // only 1 min cache for repeat calls

  return ctx ? (ctx as MonolithProfile) : {};
}

export async function getBackoutsDetails(
  params: BackoutsDetailsFilters,
): Promise<BackoutsDetails> {
  const {
    userId,
    dayHistory,
    pastHistory,
    typeOfWork,
    companyId,
    graphType,
  } = params;

  const resp = await requestWrapper<BackoutsDetails>(`/p/user/${userId}/backouts`, {
    method: 'POST',
    body: {
      filters: {
        dayHistory: dayHistory || undefined,
        pastHistory: pastHistory || undefined,
        typeOfWork: typeOfWork || undefined,
        companyId: companyId || undefined,
        graphType: graphType || undefined,
      },
    },
  });

  return resp as BackoutsDetails;
}

export async function getJobHistory(
  params: JobHistoryParams,
  options: RequestOptions = {},
): Promise<JobHistory> {
  const {
    userId,
    contextUserId,
    contextGroupId,
    companyId = 0,
    managedOrSelf,
    filters = {},
  } = params;

  const resp = await requestWrapper(`/p/user/${userId}/job-history/${companyId}`, {
    ...options,
    method: 'POST',
    body: {
      filters: {
        ...filters,
        userId,
        contextUserId,
        contextGroupId,
        contextCompanyId: companyId,
        managedOrSelf,
      },
    },
  });

  return resp as JobHistory;
}

export async function getIndustries(
  _options: RequestOptions = {},
): Promise<MonolithIndustry[]> {
  // eslint-disable-next-line no-underscore-dangle
  if (!window._TYPES_OF_WORK || typeof window._TYPES_OF_WORK !== 'object') {
    return [];
  }

  // eslint-disable-next-line no-underscore-dangle
  return window._TYPES_OF_WORK;
}

export async function approveComment(
  workOrderId: number,
  options: RequestOptions = {},
) {
  const res: any = await requestWrapper(`/v2/ratings/workorders/${workOrderId}/comments`, {
    ...options,
    method: 'PUT',
    body: {
      status: 'approved',
    },
  });

  // update user_job_history document in ES
  if (res?.success) {
    await requestWrapper(`/p/user/:userId/job-history/${workOrderId}`, {
      ...options,
      method: 'PUT',
      body: {
        updateData: {
          reply_approved: true,
        },
      },
    });
  }
}

export async function flagComment(
  workOrderId: number,
  options: RequestOptions = {},
) {
  const res: any = await requestWrapper<any>(`/v2/ratings/workorders/${workOrderId}/comments`, {
    ...options,
    method: 'PUT',
    body: {
      status: 'pending',
    },
  });

  // update user_job_history document in ES
  if (res?.success) {
    await requestWrapper(`/p/user/:userId/job-history/${workOrderId}`, {
      ...options,
      method: 'PUT',
      body: {
        updateData: {
          reply_approved: false,
        },
      },
    });
  }
}

export async function addOrUpdateComment(
  workOrderId: number,
  comment: string,
  options: RequestOptions = {},
) {
  const res: any = await requestWrapper<any>(`/v2/ratings/workorders/${workOrderId}/comments`, {
    ...options,
    method: 'PUT',
    body: {
      comment,
    },
  });

  // update user_job_history document in ES
  if (res?.success) {
    await requestWrapper(`/p/user/:userId/job-history/${workOrderId}`, {
      ...options,
      method: 'PUT',
      body: {
        updateData: {
          reply_text: comment,
        },
      },
    });
  }
}

export async function getUserLicensesCertifications(
  userId: number,
) {
  const userLicenseCertification = await requestWrapper(`/qualifications/users/${userId}/qualifications`, {
    method: 'GET',
  });

  return userLicenseCertification ? (userLicenseCertification as UserQualifications[]) : [];
}

export async function saveUserLicensesCertifications(
  userId: number,
  data: UserQualifications,
) {
  if (
    !data.category
    || !data.dt_number
    || !data.issue_date
    || !data.expire_date
  ) {
    return false;
  }

  const type = data.category === 1 ? 'certification' : 'license';
  await requestWrapper(`/v2/users/${userId}/${type}s/${data.dt_number}`, {
    method: 'POST',
    body: {
      ...data,
    },
  });

  return true;
}

export async function updateUserLicensesCertifications(
  userId: number,
  data: UserQualifications,
) {
  if (
    !data.category
    || !data.dt_number
    || !data.issue_date
    || !data.expire_date
  ) {
    return false;
  }
  const type = data.category === 1 ? 'certification' : 'license';
  await requestWrapper(`/v2/users/${userId}/${type}s/${data?.dt_number}`, {
    method: 'PUT',
    body: {
      ...data,
    },
  });

  return true;
}

export async function deleteUserLicensesCertifications(
  userId: number,
  data: UserQualifications,
) {
  if (!data?.category || !data?.dt_number) {
    return false;
  }

  const type = data?.qualification?.category?.id === 2 ? 'license' : 'certification';

  await requestWrapper(`/v2/users/${userId}/${type}s/${data?.dt_number}`, {
    method: 'DELETE',
  });

  return true;
}

export async function getLicenseOrCertificationByInput(
  search: string,
  type: string,
) {
  if (type !== 'license' && type !== 'certification') {
    return [];
  }

  const { results } = await <any>requestWrapper(
    `/qualifications/qualifications/search/${type}/${search}`,
    {
      method: 'GET',
    },
  );

  return results;
}

export async function saveDynamicTerm(
  name: string,
  type: string,
) {
  if (!type) {
    return false;
  }

  const results = await requestWrapper('/v2/system/dynamic-terms', {
    method: 'POST',
    body: {
      name,
      type,
    },
  });

  return results;
}

export async function getUserScreeningsData(
  vanityOrUserId: number | undefined,
): Promise<BackgroundCheckApiResponse> {
  const reports = await Promise.race([
    await requestWrapper(
      `/micro/background-checks/candidates/${vanityOrUserId}/reports/latest_cleared`,
    ),
    new Promise((_, reject) => setTimeout(reject, 9500)),
  ]);

  return reports ? (reports as BackgroundCheckApiResponse) : {};
}

export async function getDistance(
  workOrderId: number,
  userId: number,
  options: RequestOptions = {},
): Promise<DistanceResponse[]> {
  const user = await requestWrapper(`/v2/workorders/${workOrderId}/distance?user_id[]=${userId}`, options);
  return user as DistanceResponse[];
}

export async function getTalentType(companyId: number, options: RequestOptions = {}) {
  const talentType = (await requestWrapper(
    `/dynamic-talent-pools/company/${companyId}/talent-pools`,
    options,
  )) as MonolithPreferredGroups;
  const talentTypeResults = {
    ...talentType,
    results: talentType?.results,
  };
  return talentType ? (talentTypeResults as MonolithPreferredGroups) : {};
}

export async function setTalentPoolsForUser(
  userId: number,
  talentPools: number[],
  talentPoolName: string,
  options: RequestOptions = {},
) {
  return requestWrapper('/dynamic-talent-pools/v2/multi-stores', {
    ...options,
    method: 'PATCH',
    body: {
      providerIds: [userId],
      name: talentPoolName,
      talentPoolIds: talentPools,
    },
  });
}

export async function createTalentPool(
  name: string,
  onError: (resp?: Response | Error | undefined) => void,
) {
  return requestWrapper('/v2/preferred-provider-groups', {
    body: { name },
    method: 'POST',
    onError,
  });
}

const feedbackUrl = () => '/feedback';

export const fetchFeedbackData = async (userId: number, page: number) => {
  const companyId = window?.context?.company?.id || 0;
  if (!companyId || !userId) {
    return {};
  }

  try {
    const response = await requestWrapper(`${feedbackUrl()}/feedback/company/${companyId}?providerId=${userId}&page=${page}&limit=5`);
    return response;
  } catch {
    return {};
  }
};

export async function getProviderWorkSummary(
  userId: number,
  workOrderId?: number,
  companyId?: number,
  options: RequestOptions = {},
): Promise<ProviderSkillSegmentaionResponse[]> {
  try {
    const result = await requestWrapper(`/p/user/${userId}/skill-segmentations${workOrderId
      ? `?workOrderId=${workOrderId}` : ''}${companyId
      ? `${workOrderId ? '&' : '?'}companyId=${companyId}` : ''}`,
    {
      ...options,
      method: 'GET',
    });
    return result as unknown as ProviderSkillSegmentaionResponse[];
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getProviderReliabilityScores(userId: number) {
  const url = `${getReputationServiceURL()}/metric/get-reliability-scores`;
  const response = await requestWrapper(url, {
    method: 'POST',
    body: {
      providerIds: [userId],
      includeDetails: true,
    },
  });

  // @ts-ignore
  return response?.results?.[0] ?? {};
}
