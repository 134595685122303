/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useRef, useLayoutEffect, useEffect, useState, Suspense,
} from 'react';
import {
  RecoilRoot, useRecoilValue, useSetRecoilState,
} from 'recoil';
import classNames from 'classnames';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Loader } from '@fieldnation/platform-components';
import {
  Header,
  Footer,
  Location,
  Ratings,
  Ranking,
  Endorsements,
  LicensesAndCerts,
  ProviderNotesWrapper,
  SkillMatch,
  Screenings,
  Tags,
  UserSummary,
} from './Sections';

import SuspenseSectionWrapper from './Components/SuspenseSectionWrapper';

import {
  getSidebarConfigQuery,
  toggleSidebarConfigCollapse,
} from './queries/snapshot.query';
import currentVanity from './queries/current-vanity.atom';
import currentWorkorder from './queries/current-work-order.atom';
import { sourceAppQuery } from './queries/source-app';
import scrollToSection from '../utils/scrollToSection';
import DrawerSettingsModal from './DrawerSettingsModal';
import EditTalentPools from './Components/EditTalentPools';
import { fetchFeedbackData, getUserById } from './api';
import { ProviderSnapshotProps } from './types.d';
import { SETTING_OPTIONS } from './queries/snapshot.atom';

import css from './ProviderSnapshot.scss';
import Feedback from './Sections/Feedback';
import { isV4Segmentation } from './utils';
import WorkSummary from './Sections/WorkSummary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // don't refresh any data more than once per minute
      staleTime: 60 * 1000,
      // setting this globally so all sections lazy load correctly
      suspense: true,
    },
  },
});

const FONT_BASIS = 28;

const ProviderSnapshot = (props: ProviderSnapshotProps): JSX.Element | null => {
  const {
    workorderId,
    user,
    scrollTo,
    userId,
    hideHeader,
    stickyHeader,
    hideFooter,
    stickyFooter,
    showProviderSummary,
    onToggleExpandAll,
    hideSections = [],
    selectionRule,
    expandCollapseAll,
    ref,
    curatedNetworks,
    sourceName,
    onClose,
    overlayPosition,
  } = props;

  const setCurrentWorkOrder = useSetRecoilState(currentWorkorder);
  const setCurrentVanity = useSetRecoilState(currentVanity);
  const setSourceAppName = useSetRecoilState(sourceAppQuery);
  const drawerConfigValue = useRecoilValue(getSidebarConfigQuery);
  const updateDrawerConfigCollapse = useSetRecoilState(
    toggleSidebarConfigCollapse,
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [settingsShown, setSettingsShown] = useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [width, setWidth] = useState(0);

  const experiments = window.context?.experiments?.results || [];
  const hasProviderNotesExperiment = experiments.some(
    ({ id, value }) => id === 95 && Number(value) === 1,
  );

  const { group: { id: userGroupId = 0 } = {} } = window.context || {};
  const canSaveCompanySettings = [2, 3, 7, 9].includes(userGroupId);
  const isStorybook = process.env?.STORYBOOK === 'true';

  const [page, setPage] = useState<number>(1);
  const [feedbackData, setFeedbackData] = useState({});
  const [loading, setLoading] = useState(false);

  const feedbackDataFetching = async (targetUserId: number, perPage: number) => {
    setLoading(true);
    const data = await fetchFeedbackData(targetUserId, perPage);
    setLoading(false);
    // @ts-ignore
    setFeedbackData(data);
  };

  useEffect(() => {
    feedbackDataFetching(userId, page);
  }, [userId, page]);

  useLayoutEffect(() => {
    setWidth(containerRef?.current?.offsetWidth || 0);
  }, [containerRef?.current]);

  useEffect(() => {
    if (userId) {
      setCurrentVanity(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (workorderId) {
      setCurrentWorkOrder(workorderId);
    }
  }, [workorderId]);

  useEffect(() => {
    if (sourceName) {
      setSourceAppName(sourceName);
    }
  }, [sourceName]);

  useEffect(() => {
    if (scrollTo) {
      scrollToSection(scrollTo);
      const isExpanded =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        drawerConfigValue.columns.find((s: any) => s.setting === scrollTo)
          ?.enabled;
      if (!isExpanded) {
        // @ts-ignore
        updateDrawerConfigCollapse(scrollTo);
      }
    }
  }, [scrollTo]);

  useEffect(() => {
    if (expandCollapseAll) {
      const areAnyExpanded = Boolean(
        drawerConfigValue.columns.find(({ collapse }) => !collapse),
      );
      if (expandCollapseAll === 'COLLAPSE ALL' && !areAnyExpanded) {
        return;
      }
      // @ts-ignore
      updateDrawerConfigCollapse(expandCollapseAll);
    }
  }, [expandCollapseAll]);

  const { data } = useQuery(['user', userId], async () => getUserById(userId), {
    suspense: true,
    initialData: user,
    // keep mock data when in storybook
    staleTime: process.env.STORYBOOK === 'true' ? Infinity : 0,
  });

  if (!userId) return null;

  const fontScale = width ? width / FONT_BASIS : 14;

  return (
    <div
      className={classNames(css['snapshot-container'], {
        [css['snapshot-sticky-container']]: stickyHeader || stickyFooter,
      })}
      ref={ref}
    >
      {edit && <EditTalentPools onClose={() => setEdit(false)} />}
      {(!hideHeader && stickyHeader && data) ? (
        <Header
          user={data}
          toggleExpandAll={onToggleExpandAll}
          onClose={onClose}
          curatedNetworks={curatedNetworks}
          overlayPosition={overlayPosition}
        />
      ) : null}
      <div
        ref={containerRef}
        className={classNames(css['snapshot-inner-container'], {
          [css['snapshot-sticky']]: stickyHeader || stickyFooter,
        })}
        // set a base for all the container rem font sizes to allow fluid responsiveness
        style={{ fontSize: fontScale <= 14 ? `${fontScale}px` : '14px' }}
      >
        {(!hideHeader && !stickyHeader && data) ? (
          <Header
            user={data}
            toggleExpandAll={onToggleExpandAll}
            onClose={onClose}
            curatedNetworks={curatedNetworks}
            overlayPosition={overlayPosition}
          />
        ) : null}
        {drawerConfigValue.columns
          .filter(({ setting }) => !hideSections?.includes(setting))
          .map(({ setting, enabled, collapse }) => {
            if (!enabled) {
              return null;
            }
            switch (setting) {
              case SETTING_OPTIONS.SUMMARY:
                if (!data || !showProviderSummary) return null;
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={<></>}
                  >
                    <UserSummary
                      collapse={collapse}
                      user={data}
                      overlayPosition={overlayPosition}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.WORK_SUMMARY:
                if (!isV4Segmentation()) return null;
                return (
                  <SuspenseSectionWrapper key={setting} label={setting}>
                    <WorkSummary
                      providerId={userId}
                      workOrderId={workorderId || 0}
                      collapse={collapse}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.PRIVATE_FEEDBACK:
                return (
                  <SuspenseSectionWrapper key={setting} label={setting}>
                    <Feedback
                      loading={loading}
                      providerId={userId}
                      feedbackData={feedbackData}
                      onNext={() => setPage(page + 1)}
                      onPrev={() => setPage(page - 1)}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.LOCATION:
                if (!data) return null;
                return (
                  <SuspenseSectionWrapper label={setting} fallback={<></>}>
                    <Location
                      key={setting}
                      collapse={collapse}
                      user={data}
                      workOrderId={workorderId}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.RATINGS:
                if (!data) return null;
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={(
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-2 big" />
                            <div className="ph-col-1 empty big" />
                            <div className="ph-col-9 big" />
                            <div className="ph-col-12 big" />
                            <div className="ph-col-12 big" />
                            <div className="ph-col-12 big" />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <Ratings collapse={collapse} user={data} />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.RANKING:
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={(
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-8" />
                          </div>
                          <div className="ph-picture" />
                          <div className="ph-row">
                            <div className="ph-col-12" />
                            <div className="ph-col-10" />
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-12" />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <Ranking
                      collapse={collapse}
                      selectionRule={selectionRule || []}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.ENDORSEMENTS:
                if (!data) return null;
                return (
                  <SuspenseSectionWrapper key={setting} label={setting}>
                    <Endorsements collapse={collapse} user={data} />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.LICENSES:
                if (!data) return null;
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={(
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-4 big" />
                            <div className="ph-col-4 empty big" />
                            <div className="ph-col-2 big" />
                          </div>
                          <div className="ph-row">
                            <div className="ph-col-12" />
                            <div className="ph-col-10" />
                            <div className="ph-col-2 empty" />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <LicensesAndCerts collapse={collapse} user={data} />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.NOTES:
                return hasProviderNotesExperiment ? (
                  <SuspenseSectionWrapper key={setting} label={setting}>
                    <ProviderNotesWrapper
                      collapse={collapse}
                      workorderId={workorderId}
                      userId={userId}
                    />
                  </SuspenseSectionWrapper>
                ) : null;
              case SETTING_OPTIONS.SKILLS:
                if (!data || !data.id || isV4Segmentation()) return null;
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={(
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-8" />
                          </div>
                          <div className="ph-row">
                            <div className="ph-col-12" />
                            <div className="ph-col-10" />
                            <div className="ph-col-2 empty" />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <SkillMatch
                      userId={data.id}
                      workOrderId={workorderId}
                      collapse={collapse}
                    />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.SCREENINGS:
                return (
                  <SuspenseSectionWrapper
                    key={setting}
                    label={setting}
                    fallback={(
                      <div className="ph-item">
                        <div className="ph-col-12">
                          <div className="ph-row">
                            <div className="ph-col-4 big" />
                            <div className="ph-col-4 empty big" />
                            <div className="ph-col-2 big" />
                          </div>
                          <div className="ph-row">
                            <div className="ph-col-12" />
                            <div className="ph-col-10" />
                            <div className="ph-col-2 empty" />
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <Screenings collapse={collapse} />
                  </SuspenseSectionWrapper>
                );
              case SETTING_OPTIONS.TAGS:
                if (!data) return null;
                return (
                  <SuspenseSectionWrapper key={setting} label={setting}>
                    <Tags collapse={collapse} user={data} />
                  </SuspenseSectionWrapper>
                );
              default:
                return null;
            }
          })}
        {!hideFooter && !stickyFooter && (
          <div className={css['snapshot-footer']}>
            <Footer
              size="sm"
              actions={[
                {
                  label: 'Add to Pool',
                  type: 'secondary',
                  onClick: () => {
                    setEdit(true);
                  },
                },
              ]}
              tertiary={
                canSaveCompanySettings || isStorybook
                  ? {
                    action: {
                      icon: 'settings',
                      onClick: () => {
                        setSettingsShown(true);
                      },
                    },
                    text: ' ',
                  }
                  : undefined
              }
            />
          </div>
        )}
      </div>
      {settingsShown && (
        <DrawerSettingsModal
          onClose={() => {
            setSettingsShown(false);
          }}
        />
      )}
      {!hideFooter && stickyFooter && (
        <div className={css['snapshot-footer']}>
          <Footer
            size="sm"
            actions={[
              {
                label: 'Add to Pool',
                type: 'secondary',
                onClick: () => {
                  setEdit(true);
                },
              },
            ]}
            tertiary={
              canSaveCompanySettings || isStorybook
                ? {
                  action: {
                    icon: 'settings',
                    onClick: () => {
                      setSettingsShown(true);
                    },
                  },
                  text: ' ',
                }
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

const LoadingComp = (): JSX.Element => (<div className={css['snapshot-loader']}><Loader isLoading fixed /></div>);

const ProviderSnapshotWrapper = (props: ProviderSnapshotProps): JSX.Element => (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingComp />}>
        <ProviderSnapshot {...props} />
      </Suspense>
    </QueryClientProvider>
  </RecoilRoot>
);

export default ProviderSnapshotWrapper;
