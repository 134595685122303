/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useMemo, memo } from 'react';
import { v2 } from '@fieldnation/platform-components';
import classes from './styles.scss';
import SelectStatus from './SelectStatus';
import { VerificationStatusEnum } from './types.d';
import { UserPermissions } from '../ProfileSettings/constants';

const { TextInput } = v2;

interface UbiNumberInputProps {
  ubiNumber: string;
  hasUbi: string;
  verificationStatus: VerificationStatusEnum;
  onChangeUbiNumber(ubiNumber: string): void;
  onChangeVerificationStatus(status: VerificationStatusEnum): void;
}

const UbiNumberInput = ({
  ubiNumber,
  hasUbi,
  verificationStatus,
  onChangeUbiNumber,
  onChangeVerificationStatus,
}: UbiNumberInputProps): JSX.Element => {
  const [showError, setShowError] = useState(false);
  const isAccountant = window?.context?.group?.id === 9;
  const hasPermissionToVerify = window?.context?.permissions?.includes(UserPermissions.VERIFY_WA_UBI_NUMBER)

  if (!hasUbi && showError) {
    setShowError(false);
  }

  const doesNotHaveUbi = useMemo(() => !hasUbi || hasUbi === '0', [hasUbi]);

  const label: JSX.Element = useMemo(
    () => (
      <span
        className={`${
          doesNotHaveUbi ? classes.OptionLabelDisabled : classes.OptionLabel
        }`}
      >
        WA Unified Business Identifier(UBI)
      </span>
    ),
    [doesNotHaveUbi],
  );

  return (
    <div className={classes.UbiNumberInput}>
      <div className={classes.FlexContainer}>
        <div className={classes.UbiNumberInputStyling}>
          <TextInput
            // @ts-ignore
            label={label}
            // @ts-ignore
            placeholder="Enter 9-digit UBI number"
            type="number"
            value={doesNotHaveUbi ? '' : ubiNumber}
            onChange={(e) => {
              onChangeUbiNumber(e.target.value);
              if (
                !e?.target?.value ||
                !e?.target?.value?.match(/^\d{9,9}$/)?.length
              ) {
                setShowError(true);
              } else {
                setShowError(false);
              }
            }}
            disabled={doesNotHaveUbi}
          />
        </div>
        <div className={classes.StatusInput}>
          {(isAccountant|| hasPermissionToVerify) && (
            <SelectStatus
              verificationStatus={verificationStatus}
              onChangeVerificationStatus={onChangeVerificationStatus}
            />
          )}
        </div>
      </div>
      <span
        className={`${classes.ErrorMessageStyle} ${
          !showError ? classes.ErrorMessageDisplayNone : ''
        }`}
      >
        UBI should be a 9 digit number
      </span>
    </div>
  );
};

export default memo(UbiNumberInput);
