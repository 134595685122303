import React, { useState } from 'react';
import {
  Thumbnail,
  IconButton,
  Link,
  Icon,
} from '@fieldnation/platform-components';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import { getUserById } from './api';
import css from './ProviderNotes.scss';
import { NoteResponseValues } from './types.d';

interface Props {
  note: NoteResponseValues;
  onDelete: () => void;
  type: 'Workorder' | 'Profile';
}

// this is kind of messy but it basically slices the html at the set limit while preserving closing tags so we can still render the HTML
const sliceHTML = (html: string, textLimit: number): string => {
  let currentTextLength = 0;
  const htmlList = html.split(/(<\/?[A-Za-z0-9]*>)/g); // split by tags

  const isATag = (s) => s[0] === '<'; // Returns true if it is a tag
  const tagName = (s) =>
    s
      .replace('<', '')
      .replace('>', '')
      .replace('/', ''); // Get the tag name

  const findMatchingTag = (list: string[], i: number) => {
    const name = tagName(list[i]);
    const searchingregex = new RegExp(`<\/ *${name} *>`, 'g'); // The regex for closing mathing tag
    const sametagregex = new RegExp(`< *${name} *>`, 'g'); // The regex for mathing tag (in case there are inner scoped same tags, we want to pass those)
    let buffer = 0; // Will count how many tags with the same name are in an inner hirarchy level, we need to pass those
    for (let j = i + 1; j < list.length; j += 1) {
      if (list[j].match(sametagregex) != null) buffer += 1;
      if (list[j].match(searchingregex) != null) {
        if (buffer > 0) buffer -= 1;
        else {
          return j;
        }
      }
    }
    return -1;
  };

  let k = 0;
  let endCut = false;
  const cutArray = new Array(htmlList.length);
  while (currentTextLength < textLimit && !endCut && k < htmlList.length) {
    // As long we are still within the text limit and within the array
    if (isATag(htmlList[k])) {
      // find the matching tag
      const matchingTagindex = findMatchingTag(htmlList, k);
      if (matchingTagindex !== -1) {
        if (
          htmlList[k].length +
            htmlList[matchingTagindex].length +
            currentTextLength <
          textLimit
        ) {
          // If icluding both the tag and its closing exceeds limit, do not include them and end the cut
          currentTextLength +=
            htmlList[k].length + htmlList[matchingTagindex].length;
          cutArray[k] = htmlList[k];
          cutArray[matchingTagindex] = htmlList[matchingTagindex];
        } else {
          endCut = true;
        }
      } else if (htmlList[k].length + currentTextLength < textLimit) {
        // If icluding the tag exceeds the limit, do not include them and end the cut
        currentTextLength += htmlList[k].length;
        cutArray[k] = htmlList[k];
      } else {
        endCut = true;
      }
    } else {
      // In case it isn't a tag - trim the text
      const cutstr = htmlList[k].substring(0, textLimit - currentTextLength);
      currentTextLength += cutstr.length;
      cutArray[k] = cutstr;
    }
    k += 1;
  }
  return cutArray.join('');
};

const Note = ({ note, onDelete, type }: Props): JSX.Element => {
  // approx 5 lines of text in the provider drawer, much more in profile
  const TEXT_LIMIT = type === 'Workorder' ? 150 : 400;
  const profileNotesCheck = type === 'Profile' && note.wo_id_copy;
  const workorderNotesCheck = type === 'Workorder' && note.profile_copied_to;

  const [expanded, setExpanded] = useState(false);
  const { data, isError } = useQuery(['user', note.created_by], () => getUserById(note.created_by));

  const date = new Date(Number(note.created_at) * 1000);

  if (isError) {
    return <div />;
  }

  const renderHTML = (text: string) => {
    if (text[0] === '<') {
      // this HTML is safe, it is generated based on a limited number of allowed tags,
      // and is santized first for good measure
      const sanitizedHTML = DOMPurify.sanitize(text);

      return (
        <div
          className={css['note-text']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        />
      );
    }
    return <p className={css['note-text']}>{text}</p>;
  };

  const renderExpandableNote = (text: string) => {
    const sanitizeAndSliceHTML = (html: string, limit: number) => {
      // Slice and sanitize the HTML content
      const slicedHTML = sliceHTML(html, limit);
      return DOMPurify.sanitize(slicedHTML);
    };

    if (expanded) {
      return (
        <div className={css['note-text-expandable']}>
          {text[0] === '<' ? (
            <div
              className={css['note-text']}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          ) : (
            <p className={css['note-text']}>{text}</p>
          )}
          <div
            className={css['note-button-expandable']}
            style={{ marginBottom: '1rem' }}
          >
            <Link onClick={() => setExpanded((s) => !s)}>View less</Link>
          </div>
        </div>
      );
    }
    return (
      <div className={css['note-text-expandable']}>
        {text[0] === '<' ? (
          <div
            className={css['note-text']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizeAndSliceHTML(text, TEXT_LIMIT),
            }}
          />
        ) : (
          <p className={css['note-text']}>
            {text.length > TEXT_LIMIT ? text.slice(0, TEXT_LIMIT) : text}
          </p>
        )}
        <div
          className={css['note-button-expandable']}
          style={{ marginBottom: '1rem' }}
        >
          <Link onClick={() => setExpanded((s) => !s)}>... View more</Link>
        </div>
      </div>
    );
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  return (
    <div className={css['note-container']}>
      <div className={css['comment-thumbnail']}>
        {data?.thumbnail ? (
          <Thumbnail src={data?.thumbnail} widthAuto="4rem" />
        ) : (
          <Icon name="account" size={32} />
        )}
      </div>
      <div className={css['note-text-container']}>
        <p className={css['note-header']}>
          {`${data?.first_name || ''} ${data?.last_name || ''} posted on`}
        </p>
        <p className={css['note-header']}>
          {`${format(date, 'PPP, p')} ${moment.tz(timezone).format('z')}`}
        </p>
        <div className="u-padding-bottom--sm u-padding-top--sm">
          {note.note?.length > TEXT_LIMIT
            ? renderExpandableNote(note.note)
            : renderHTML(note.note)}
        </div>
        {workorderNotesCheck ? (
          <>
            <div className={css['note-alignment']}>
              <IconButton name="addUser" size="lg" />
              <p className={css['note-footer']}>
                Note added to provider profile notes
              </p>
            </div>
          </>
        ) : null}

        {profileNotesCheck ? (
          <>
            <div className={css['note-alignment']}>
              <IconButton name="assignment" size="lg" />
              <p className={css['note-footer']}>
                Note added from
                <Link
                  href={`/workorders/${note.wo_id_copy}`}
                  target="_blank"
                >
                  {` WO ${note.wo_id_copy}`}
                </Link>
              </p>
            </div>
          </>
        ) : null}
      </div>
      {note.actions.includes('delete') ? (
        <div className={css['note-delete']}>
          <IconButton onClick={onDelete} name="deleteHollow" size="lg" />
        </div>
      ) : null}
    </div>
  );
};

export default Note;
