(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("PlatformComponents"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("PlatformComponentWidgets", ["React", "PlatformComponents", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["PlatformComponentWidgets"] = factory(require("React"), require("PlatformComponents"), require("ReactDOM"));
	else
		root["PlatformComponentWidgets"] = factory(root["React"], root["PlatformComponents"], root["ReactDOM"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__41__) {
return 