import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridColumn,
  Button,
  Banner,
  v2,
} from '@fieldnation/platform-components';
import { Options } from '@fieldnation/platform-components/src/v2/Select/types.d';
import {
  ShipmentFormProps, ShipmentFormValue, Location,
} from './types.d';
import styles from './styles.scss';
import { SmartAddressWidget } from '../SmartAddress';
import { IFullAddressInputAttribute, IFullAddressInputValues } from '../SmartAddress/types.d';
import DescriptionField from './components/DescriptionField';
import TrackingField from './components/TrackingField';
import FormError from './components/FormError';
import { getCarrierFromTrackingNumber, getDirections } from './utils';

const {
  Select, TextInput,
} = v2;

const defaultValue: ShipmentFormValue = {
  id: -1,
  direction: '',
  name: '',
  carrier: {
    name: '',
    tracking: '',
  },
  tracking_link: '',
  location: {
    name: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
};

const otherLocationDefault: IFullAddressInputValues = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
};

const ShipmentForm = ({
  isProvider,
  disableOtherLocation,
  otherLocation,
  isEditMode,
  data,
  onDataChange,
  onDelete,
  errors,
  collectLocation = true,
}: ShipmentFormProps): JSX.Element => {
  const [formData, setFormData] = useState<ShipmentFormValue>(defaultValue);
  const [smartAddressValues, setSmartAddressValues] = useState<IFullAddressInputValues>(otherLocationDefault);
  const [sawInputAttributes, setSawInputAttributes] = useState<IFullAddressInputAttribute>();

  useEffect(() => {
    const { carrier, trackingLink } = getCarrierFromTrackingNumber(data.carrier?.tracking || '');
    setFormData({
      ...data,
      carrier: {
        ...data.carrier,
        name: carrier,
      },
      tracking_link: trackingLink,
    });

    const location = data?.location?.address1 || data?.location?.city || data?.location?.state
      ? data?.location : otherLocation;
    setSmartAddressValues({
      address1: location?.address1 ?? '',
      address2: location?.address2 ?? '',
      city: location?.city ?? '',
      state: location?.state ?? '',
      zip: location?.zip ?? '',
      country: location?.country ?? 'US',
    });
  }, []);

  useEffect(() => {
    if (data?.location?.address1 !== smartAddressValues?.address1 && !otherLocation) {
      setSmartAddressValues({
        address1: data?.location?.address1 ?? '',
        address2: data?.location?.address2 ?? '',
        city: data?.location?.city ?? '',
        state: data?.location?.state ?? '',
        zip: data?.location?.zip ?? '',
        country: data?.location?.country ?? 'US',
      });
    }
  }, [data?.location?.address1]);

  useEffect(() => {
    setSawInputAttributes({
      address1: {
        additionalContent: errors?.location?.address1 === true ? <FormError message="Address is required" /> : null,
      },
      city: {
        additionalContent: errors?.location?.city === true ? <FormError message="City is required" /> : null,
      },
      state: {
        additionalContent: errors?.location?.state === true ? <FormError message="State is required" /> : null,
      },
      zip: {
        additionalContent: errors?.location?.zip === true ? <FormError message="Zip is required" /> : null,
      },
      country: {
        additionalContent: errors?.location?.country === true ? <FormError message="Country is required" /> : null,
      },
    });
  }, [errors]);

  const handleDataChange = (key: string, changedValue: string) => {
    const updatedValue = {
      ...formData,
      [key]: changedValue,
    };
    setFormData(updatedValue);
    onDataChange(updatedValue);
  };
  const handleNestedChange = (parent: string, key: string, changedValue: string) => {
    const updatedValue = {
      ...formData,
      [parent]: {
        ...formData?.[parent],
        [key]: changedValue,
      },
    };
    setFormData(updatedValue);
    onDataChange(updatedValue);
  };

  const handleSmartAddressChange = (address: IFullAddressInputValues) => {
    const locationValue: Location = {
      ...formData?.location,
      address1: address?.address1,
      address2: address?.address2,
      city: address?.city,
      state: address?.state,
      zip: address?.zip,
      country: address?.country,
    };
    const updatedValue = {
      ...formData,
      location: locationValue,
    };
    setFormData(updatedValue);
    onDataChange(updatedValue);
  };

  return (
    <div className={`${styles.formContainer} ${!isEditMode ? styles.addMode : styles.editMode}`}>
      {/* Two Column Layout */}
      <div className={styles.formLayout}>
        {/* Left Column - Form Content */}
        <div className={!isEditMode ? styles.formContent : styles.formContentEditMode}>
          <Grid>
            <GridColumn xs="12">
              <div className={styles.formField}>
                <Select
                  label="Shipping route"
                  onChange={(t: Options) => handleDataChange('direction', t?.value?.toString() || '')}
                  value={formData?.direction || ''}
                  options={getDirections(isProvider, disableOtherLocation)}
                  disabled={false}
                />
                {errors.direction === true ? <FormError message="Shipping route is required" /> : null}
              </div>
            </GridColumn>

            {formData?.direction ?
              (
                <>
                  <GridColumn xs="12">
                    <div className={styles.formField}>
                      <TrackingField
                        value={{
                          ...formData?.carrier,
                          name: formData?.carrier?.name || '',
                          tracking: formData?.carrier?.tracking || '',
                          other_name: formData?.carrier?.other_name || '',
                          tracking_link: formData?.tracking_link || '',
                        }}
                        onChange={(carrierInfo) => {
                          const updatedValue = {
                            ...formData,
                            tracking_link: carrierInfo?.tracking_link,
                            carrier: carrierInfo,
                          };
                          setFormData(updatedValue);
                          onDataChange(updatedValue);
                        }}
                        disabled={false}
                        errors={errors.carrier}
                      />
                    </div>
                  </GridColumn>
                  <GridColumn xs="12">
                    <div className={styles.formField}>
                      <DescriptionField
                        value={formData?.name || ''}
                        onChange={(e) => handleDataChange('name', e.target.value)}
                        maxLength={100}
                      />
                      {errors.name === true ? <FormError message="Description is required" /> : null}
                    </div>
                  </GridColumn>
                  {formData?.direction === 'to other location' && collectLocation ?
                    (
                      <>
                        <GridColumn xs="12">
                          <hr className={styles.divider} />
                        </GridColumn>
                        <GridColumn xs="12">
                          <h3 className={styles.sectionTitle}>Pickup location</h3>
                        </GridColumn>
                        {!!otherLocation?.address1
                          && (
                          <GridColumn xs="12">
                            <div className={styles.otherLocationInfo}>
                              <Banner
                                message="Editing pickup location will update all 'Shipping to other location' shipments"
                                type="info"
                              />
                            </div>
                          </GridColumn>
                          )}
                        <GridColumn xs="12">
                          <div className={styles.formField}>
                            <TextInput
                              optional
                              label="Location name"
                              value={formData?.location?.name ? formData?.location?.name : (otherLocation?.name ?? '')}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNestedChange('location', 'name', e?.target?.value || '')}
                              disabled={false}
                            />
                          </div>
                        </GridColumn>
                        <GridColumn xs="12">
                          <SmartAddressWidget
                            values={smartAddressValues}
                            onChange={(values: IFullAddressInputValues) => {
                              setSmartAddressValues(values);
                              handleSmartAddressChange(values);
                            }}
                            inputAttribute={sawInputAttributes}
                          />
                        </GridColumn>
                      </>
                  ) : null}
                  {formData?.direction === 'to other location' && !collectLocation ?
                    (
                      <GridColumn xs="12">
                        <Banner
                          message="All 'Shipping to other location' shipments have the same pickup location"
                          type="info"
                        />
                      </GridColumn>
                  ) : null}

                </>
            ) : null}

          </Grid>
        </div>

        {/* Right Column - Delete Button */}
        {!isEditMode && (
          <div className={styles.deleteSection}>
            <Button
              icon="deleteHollow"
              onClick={() => onDelete(formData.id)}
              shouldUseIconComponent
              type="text"
              className={styles.deleteButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentForm;
