/* eslint-disable react/prop-types */
import React, { Suspense, useRef, useEffect } from 'react';
import { useRecoilValue, useRecoilState, RecoilRoot } from 'recoil';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  v2,
  TitleBar,
  Heading,
  Loader,
  Link,
} from '@fieldnation/platform-components';

import css from './TaxExemptionsList.scss';

import {
  ExemptionsDataSelector,
  tableColumnsAtom,
  tableSortAtom,
  compareASC,
  compareDESC,
} from './exemptionsData.selector';
import { CertCaptureTokenSelector, CertCaptureCustomerAtom, CertCaptureShipZoneAtom } from './CertCaptureToken.selector';
import { TaxableStatesSelector } from './TaxableStates.selector';
import { initiate } from './CertCapturePlugin';
import { TitleBarAction } from '@fieldnation/platform-components/src/TitleBar/types';

const { Listing, Modal, Select } = v2;

export const TAXABLE_COUNTRIES = ['US'];

export function getStateOptions(states: Array<{ stateName: string }>) {
  return states
    ?.sort((x, y) => {
      const a = x?.stateName?.toUpperCase();
      const b = y?.stateName?.toUpperCase();
      return a == b ? 0 : (a > b ? 1 : -1);
    })
    ?.map(s => ({ label: s?.stateName, value: s?.stateName })) || [];
}


const TaxExemptionsListTable = ({
  rawData,
  dataURL,
  dataURLOptions,
  withHeader,
  company,
}) => {
  const data = useRecoilValue(
    ExemptionsDataSelector({ rawData, dataURL, dataURLOptions }),
  ) || [];

  const [tableColumns, setTableColumns] = useRecoilState(tableColumnsAtom);
  const [sortDirection, setTableSort] = useRecoilState<any>(tableSortAtom);

  const onColumnSort = (column: string, direction: string) => {
    setTableSort({ sortColumn: column, sortOrder: direction });
    const newTableHeaders = tableColumns.map((col: any) => {
      if (col.id === column) {
        return { ...col, sortDirection: direction };
      }
      // eslint-disable-next-line no-param-reassign
      delete col.sortDirection;
      return col;
    });
    setTableColumns(newTableHeaders);
  };

  const processAndSortData = () => {
    const currentData = data;

    if (sortDirection) {
      return currentData.sort((item1, item2) => {
        const itemA = item1?.[sortDirection?.sortColumn];
        const itemB = item2?.[sortDirection?.sortColumn];
        if (sortDirection?.sortOrder === 'ASC') {
          return compareASC(itemA, itemB);
        }
        if (sortDirection?.sortOrder === 'DESC') {
          return compareDESC(itemA, itemB);
        }
        return 0;
      });
    }
    return currentData;
  };

  const onColumnResize = (id: string, size: number) => {
    const newTableHeaders = tableColumns.map((col) => {
      if (col.id === id) return { ...col, width: size };
      return col;
    });
    setTableColumns(newTableHeaders);
  };

  const getTableConfig = () => ({
    data: processAndSortData(),
    columns: tableColumns,
    stickyFirstColumn: false,
    disableTableBorder: false,
    onColumnResize,
    onColumnSort,
    itemsPerPage: 100,
    currentPage: 1,
    totalRecords: data.length || 0,
  });

  const [ _, setCustomer ] = useRecoilState(CertCaptureCustomerAtom);
  const [ shipZone, setShipZone ] = useRecoilState(CertCaptureShipZoneAtom);
  const token = useRecoilValue(CertCaptureTokenSelector);
  const taxableStates = useRecoilValue(TaxableStatesSelector);
  const createCertificateFormContainer = useRef(null);

  useEffect(() => {
    if (shipZone && token && createCertificateFormContainer) {
      initiate(token, createCertificateFormContainer?.current, shipZone);
    }
  });

  const titleBarActions: Array<TitleBarAction> = [];
  if (TAXABLE_COUNTRIES.includes(company?.country)) {
    titleBarActions.push({
      label: 'Create Certificate',
      onClick: () => {
        setCustomer(company);
       },
      type: 'primary',
    });
  }

  return (
    <div className={classNames(css['exemptions-container'])}>
      <Modal
        isOpen={token ? true : false}
        header={!shipZone ? 'Select State' : `Create Certificate for the State of ${shipZone}`}
        onClose={() => {
          setCustomer(null);
          window.location.reload();
        }}
        footerActions={[]}
        size="large">
          <div ref={createCertificateFormContainer} id="create-certificate-form-container"></div>
          {
            !shipZone
            &&
            <Select
              label="State"
              value={shipZone}
              options={getStateOptions(taxableStates)}
              onChange={(selectedItem) => setShipZone(String(selectedItem?.value))}
            />
          }
      </Modal>
      {withHeader && (
        <div className={classNames(css['title-bar'])}>
          <TitleBar
            // @ts-ignore
            title={<Heading styleLevel="h3">Sales & Use Tax Exemptions</Heading>}
            actions={titleBarActions}
          />
        </div>
      )}
      <div className={classNames(css['content-container'])}>
        {withHeader && (
          <div className={classNames(css['listing-header'])}>
            <div className={classNames(css['header-message'])}>
              <span>
                Field Nation is required to collect or remit sales and use tax
                on your behalf for taxable work taking place in states where
                applicable unless we have a valid exempt or resale certificate
                on file for those states. Note: Exempt or Resale certificates do
                not apply to the SaaS (software as a service) fees you pay to
                Field Nation. Visit our
                <Link
                  target="_blank"
                  href="https://go.fieldnation.com/sales-use-tax-faq"
                >
                  <span> </span>
                  FAQ page
                  <span> </span>
                </Link>
                for more details.
                <br />
                <br />
                Here&apos;s a list of your current sales & use tax exemption
                certificates by state and the status of each certificate.
                <br />
              </span>
            </div>
          </div>
        )}
        <div className={classNames(css['listing-table'])}>
          <Listing
            views={['list']}
            activeView="list"
            toolbarEnabled={false}
            tableConfig={getTableConfig()}
          />
        </div>
      </div>
    </div>
  );
};

const TaxExemptionsList = (props) => (
  <RecoilRoot>
    <Suspense fallback={<Loader fixed isLoading />}>
      <TaxExemptionsListTable {...props} />
    </Suspense>
  </RecoilRoot>
);

TaxExemptionsList.propTypes = {
  rawData: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  dataURL: PropTypes.string,
  dataURLOptions: PropTypes.object,
  withHeader: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
  })
};

TaxExemptionsList.defaultProps = {
  dataURL: '',
  dataURLOptions: {},
  withHeader: true,
  company: {
    id: 0,
  },
};

export default TaxExemptionsList;
