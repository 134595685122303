import { atom } from 'recoil';
import {
  localStorageAtomEffect,
  SNAPSHOT_KEY,
} from '../../utils/localStorageHelper';
import { isV4Segmentation } from '../utils';

const SETTING_OPTIONS = {
  SUMMARY: 'Summary',
  LOCATION: 'Location',
  RATINGS: 'Ratings',
  NOTES: 'Provider Notes',
  RANKING: 'Ranking',
  ENDORSEMENTS: 'Endorsements',
  SCREENINGS: 'Screenings',
  TAGS: 'Tags',
  LICENSES: 'Licenses and certifications',
  SKILLS: 'Provider Skills',
  PRIVATE_FEEDBACK: 'Private Feedback',
  WORK_SUMMARY: 'Work Summary',
} as const;

export type SettingOptions = typeof SETTING_OPTIONS[keyof typeof SETTING_OPTIONS];

interface ColumnConfig {
  configVersion: number;
  columns: Setting[];
}

interface Setting {
  setting: SettingOptions;
  enabled?: boolean; // Visible or hidden
  collapse?: boolean; // Collapsed or not
  whatVersionWasColumnIntroduced: number; // NEEDS TO BE DEFINED IN DEFAULT SETTINGS
}

/**
 * Add new array element here if you want them to be displayed in the sidebar.
 */
const defaultSettingsColumns = [
  {
    setting: SETTING_OPTIONS.SUMMARY,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 2,
  },
  {
    setting: SETTING_OPTIONS.WORK_SUMMARY,
    enabled: isV4Segmentation(),
    collapse: false,
    whatVersionWasColumnIntroduced: 4,
  },
  {
    setting: SETTING_OPTIONS.LOCATION,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.RATINGS,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.PRIVATE_FEEDBACK,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 3,
  },
  {
    setting: SETTING_OPTIONS.NOTES,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.RANKING,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.ENDORSEMENTS,
    enabled: false,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.SCREENINGS,
    enabled: false,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.TAGS,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.LICENSES,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
  {
    setting: SETTING_OPTIONS.SKILLS,
    enabled: true,
    collapse: false,
    whatVersionWasColumnIntroduced: 1,
  },
];

/**
 * Add new sections here if you want them to be displayed in the sidebar.
 */
const defaultSettings: ColumnConfig = {
  // uptick this if you add new columns below - this auto keeps columns in sync with use saved ones
  configVersion: 4,
  columns: !isV4Segmentation() ?
    defaultSettingsColumns.filter((column) => column.setting !== SETTING_OPTIONS.WORK_SUMMARY)
    : defaultSettingsColumns,
};

// Need to store as JSON string value because of the way recoil does comparisons
const snapshotState = atom<string>({
  key: 'snapshotState',
  default: JSON.stringify(defaultSettings),
  effects: [
    localStorageAtomEffect(SNAPSHOT_KEY),
  ],
});

export {
  defaultSettings,
  snapshotState,
  SETTING_OPTIONS,
};

export type {
  ColumnConfig,
  Setting,
};
