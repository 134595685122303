export type JSObject = { [key: string]: any };

export interface RequestOptions {
  headers?: { [index: string]: string };
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
  body?: any;
  onError?: (resp?: Response | Error) => any;
  throwExceptions?: boolean;
  timeout?: number;
  query?: JSObject;
}

export async function requestWrapper(
  url: string,
  options: RequestOptions = {},
) {
  const {
    headers = {},
    method = 'GET',
    body,
    onError,
    throwExceptions = false,
    timeout = 30000, // 30s
  } = options;

  try {
    const resp = (await Promise.race(
      [
        fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...headers,
          },
          credentials: 'same-origin',
          cache: 'no-cache',
          mode: 'cors',
          body: body ? JSON.stringify(body) : undefined,
        }),
        new Promise((_, reject) =>
          setTimeout(() => {
            reject(new Error(`Timed out connecting to: ${method} ${url}`));
          }, timeout),
        ),
      ])) as Response;

    // if the status code is related with backend server errors
    if (resp.status >= 400 && resp.status < 600) {
      const msg = await resp.json();
      console.error(
        `${method} ${url} [${resp.status}]: ${
          msg ? JSON.stringify(msg, null, 2) : resp.statusText
        }`,
      );

      // if we passed down any value for throwExceptions. default is false
      if (throwExceptions) {
        throw new Error('Failed to load data -- you may not have access.');
      }

      // if we wanted to run any custom function on error. default is undefined
      // Ex: a common flash error
      if (onError) {
        return onError(resp);
      }

      return null;
    }

    // if the status code is not within possible success response codes
    if (!resp.ok || resp.status < 200 || resp.status > 299) {
      if (throwExceptions) {
        throw new Error(
          'Data is not available right now. Please try again in a few minutes.',
        );
      }

      if (onError) {
        return onError(resp);
      }
      console.error(`[${resp.status}]: ${resp.statusText}`);
      return null;
    }

    const contentType = resp?.headers?.get('content-type');
    if (contentType && contentType.indexOf('text/html') !== -1) {
      return resp.text();
    }

    return resp.json();
  } catch (err) {
    if (throwExceptions) {
      throw err;
    }

    if (onError) {
      return onError(err as Error);
    }

    return null;
  }
}
